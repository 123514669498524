import { FilterValue, SorterResult, SortOrder, TablePaginationConfig } from 'antd/lib/table/interface';
import moment from 'moment';
import Constants from '../Constants';
import {
  Classification,
  QueryResponse,
  Question,
  QuestionDetail,
  QuestionDuplicated,
  QuestionSimilar,
  QuestionDuplicatesFeedback,
  QuestionDuplicateFeedbackV2,
} from '../models/Question';
import { GeneratedAnswer } from '../models/GeneratedAnswer';
import { ErrorResponse } from '../models/ErrorResponse';
import { QuestionEvent } from '../models/QuestionEvent';
import CreateQuestionRequest from '../models/requests/CreateQuestionRequest';
import UpdateQuestionRequest from '../models/requests/UpdateQuestionRequest';
import ApiGateway from './ApiGateway';

type Sorter = { order: SortOrder | undefined; field: string | undefined };

export default class QuestionService {
  private readonly GET_QUESTIONS = '/questions/query';
  private readonly GET_QUESTION_DETAILS = '/questions/getDetails';
  /* private readonly GET_QUESTION_DUPLICATES = '/questions/duplicates'; */
  private readonly PUT_QUESTION_DUPLICATES_FEEDBACK = '/questions/duplicates/feedback';
  private readonly GET_STATUS_HISTORY = '/questions/getStatusHistory';
  private readonly QUESTIONS_CREATE = '/questions/submit';
  private readonly ASSIGN_QUESTION = '/questions/assign';
  private readonly ASSIGN_MULTIPLE_QUESTIONS = '/questions/assignMultiple';
  private readonly ACCEPT_QUESTION = '/questions/accept';
  private readonly ASK_END_USER_FOR_INFO = '/questions/info';
  private readonly RETURN_TO_ASSIGN_STATUS = '/questions/skipWaitingUserResponse';
  private readonly DEFINITIVE_REFUSE_QUESTION = '/questions/reject';
  private readonly PROPOSE_ANSWER = '/questions/answer/propose';
  private readonly ACCEPT_ANSWER = '/questions/answer/accept';
  private readonly REFUSE_ANSWER = '/questions/answer/refuse';
  private readonly INTEGRATE_ANSWER = '/questions/answer/integrate';
  private readonly UPDATE_QUESTION = '/questions/update';
  private readonly READY_QUESTION = '/questions/ready';
  private readonly NOTIFY_QUESTION = '/questions/notify';
  private readonly UNLOCK_NOTIFIED_QUESTION = '/questions/notify/unlock';
  private readonly PUBLISH_QUESTION = '/questions/publish';
  private readonly REPUBLISH_QUESTION = '/questions/republish';
  private readonly QUESTION_ASK_VALIDATION = '/questions/askValidation';
  private readonly VALIDATE_QUESTION = '/questions/validate';
  private readonly REMOVE_QUESTION_FROM_OUTPUT_CHANNEL = '/questions/publish/remove';
  private readonly UNPUBLISH_QUESTION = '/questions/unpublish';
  private readonly UNLOCK_PUBLISHED_QUESTION = '/questions/publish/unlock';
  private readonly RECLASSIFY_QUESTION = '/questions/reclassify';
  private readonly ADD_COMMENT = '/questions/comment/add';
  private readonly DELETE_COMMENT = '/questions/comment/delete';
  private readonly DISCARD_QUESTION = '/questions/discard';
  private readonly REPORT_STATUS_QUESITI = '/reports/count-question-by-status';
  private readonly CREATE_GENAI_ANSWER = '/answerGenAi/create';
  private readonly ADD_FEEDBACK_GENAI = '/answerGenAi/addFeedback';
  private readonly GET_QUESTION_SIMILAR = '/questions/similar';
  private readonly GET_QUESTION_DUPLICATES = '/questions/duplicatesV2';
  private readonly PUT_QUESTION_DUPLICATES_FEEDBACK_V2 = '/questions/duplicates/feedbackV2';

  async createQuestion(request: CreateQuestionRequest): Promise<void> {
    await ApiGateway.post(this.QUESTIONS_CREATE, request);
  }

  getSorter = (sorter: SorterResult<Question> | SorterResult<Question>[]): Sorter | Sorter[] => {
    if (Array.isArray(sorter)) {
      return sorter.map((s) => ({ order: s.order, field: s.field as string }));
    }
    return {
      order: sorter.order,
      field: sorter.field as string,
    };
  };

  async getQuestions(
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Question> | SorterResult<Question>[],
    pagination: TablePaginationConfig,
  ): Promise<QueryResponse> {
    const theSorter = this.getSorter(sorter);
    const payload = {
      queryId: 'AllQuestionsQuery',
      filters: filters,
      sorter: theSorter,
      pagination: pagination,
    };

    // Configurazione specifica per AllQuestionsQuery
    const config = {
      timeout: 60000, // 60 secondi di timeout
    };

    const response = await ApiGateway.post(this.GET_QUESTIONS, payload, config);
    return this.mapResponseToQueryResponse(response);
  }

  async getQuestionDetails(questionId: string): Promise<QuestionDetail> {
    const results = await Promise.allSettled([
      ApiGateway.get(this.GET_QUESTION_DETAILS, { questionId }),
      ApiGateway.get(this.GET_QUESTION_DUPLICATES, { questionId }),
      ApiGateway.get(this.GET_QUESTION_SIMILAR, { questionId }),
    ]);

    // NOTA: Promise.allSettled restituisce un array di oggetti con proprietà status e value (se risolta) o reason (se in errore)
    const details = results[0].status === 'fulfilled' ? results[0].value.data : {};
    const duplicates = results[1].status === 'fulfilled' ? results[1].value.data : { suggestions: [] };
    const similar = results[2].status === 'fulfilled' ? results[2].value.data : { suggestions: [] };

    return this.mapResponseToQuestionDetail(details, duplicates, similar);
  }

  async getStatusHistory(questionId: string): Promise<QuestionEvent[]> {
    const response = await ApiGateway.get(this.GET_STATUS_HISTORY, { questionId });
    return response.data;
  }

  async getQuestionsByContainer(containerId: string): Promise<Question[]> {
    const response = await ApiGateway.post(this.GET_QUESTIONS, {
      queryId: 'QuestionsByContainerQuery',
      filters: { containerId: [containerId] },
      sorter: { order: 'ascend', field: 'sequenceNumber' },
    });
    return response.data.questions.map(this.mapResponseToQuestion);
  }

  async getPublishableQuestions(
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Question>,
    pagination: TablePaginationConfig,
  ): Promise<QueryResponse> {
    const response = await ApiGateway.post(this.GET_QUESTIONS, {
      queryId: 'PublishableQuestionsQuery',
      filters: filters,
      sorter: { order: sorter.order, field: sorter.field },
      pagination: pagination,
    });
    return this.mapResponseToQueryResponse(response);
  }

  async getWorkableQuestions(
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Question> | SorterResult<Question>[],
    pagination: TablePaginationConfig,
  ): Promise<QueryResponse> {
    const theSorter = this.getSorter(sorter);
    const response = await ApiGateway.post(this.GET_QUESTIONS, {
      queryId: 'WorkableQuestionsQuery',
      filters: filters,
      sorter: theSorter,
      pagination: pagination,
    });
    return this.mapResponseToQueryResponse(response);
  }

  async getMyQuestions(
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Question> | SorterResult<Question>[],
    pagination: TablePaginationConfig,
  ): Promise<QueryResponse> {
    const theSorter = this.getSorter(sorter);
    const response = await ApiGateway.post(this.GET_QUESTIONS, {
      queryId: 'MyQuestionsQuery',
      filters: filters,
      sorter: theSorter,
      pagination: pagination,
    });
    return this.mapResponseToQueryResponse(response);
  }

  async assignQuestionTo(questionId: string, userId: string): Promise<void> {
    try {
      await ApiGateway.post(this.ASSIGN_QUESTION, {
        questionId: questionId,
        userId: userId,
      });
    } catch (e: any) {
      console.error('assignQuestionToExpert', e);
      throw new Error("Impossibile assegnare il quesito all'esperto: " + e.toString());
    }
  }

  async assignMultipleQuestionsTo(questionIds: string[], userId: string): Promise<void> {
    const queryString = questionIds.map(questionId => `${questionId}`).join(',');
    try {
      const response = await ApiGateway.post(this.ASSIGN_MULTIPLE_QUESTIONS, {
        questionIds: queryString,
        userId: userId,
      }).then(resp => resp.data);

      if (response.status === 'error') {
        // Handle error response
        const errorResponse = response as ErrorResponse;
        errorResponse.errors.forEach(error => {
          console.error(`Error assigning question ${error.questionId}: ${error.error}`);
        });
        throw new Error(JSON.stringify(errorResponse));
      } else {
        console.log('Assign success!');
      }
    } catch (e: any) {
      console.error('assignMultipleQuestionsToExpert', e);
      throw new Error("Impossibile assegnare i quesiti all'esperto: " + e.toString());
    }
  }

  async askEndUserForInfo(questionId: string, message: string) {
    try {
      await ApiGateway.post(this.ASK_END_USER_FOR_INFO, {
        questionId: questionId,
        message: message,
      });
    } catch (e: any) {
      console.error('askEndUserForInfo', e);
      throw new Error(
        `Impossibile richiedere informazioni all'utente finale. (Dettaglio dell'errore: ${JSON.stringify(e)})`,
      );
    }
  }

  async acceptOrRejectByExpertQuestion(questionIds: string[], accept: boolean) {
    try {
      await ApiGateway.post(this.ACCEPT_QUESTION, {
        questionIds: questionIds,
        accept: accept,
      });
    } catch (e: any) {
      console.error('acceptQuestion', e);
      throw new Error(
        `Impossibile ${accept ? 'accettare' : 'rifiutare'} il quesito. (Dettaglio dell'errore: ${JSON.stringify(e)})`,
      );
    }
  }

  async returnToAssignStatus(questionId: string) {
    try {
      await ApiGateway.post(this.RETURN_TO_ASSIGN_STATUS, {
        questionId: questionId,
      });
    } catch (e: any) {
      console.error('returnToAssignStatus', e);
      throw new Error(
        `Impossibile far ritornare il quesito in assegnato. (Dettaglio dell'errore: ${JSON.stringify(e)})`,
      );
    }
  }

  async discardQuestion(questionIds: string[]) {
    try {
      await ApiGateway.post(this.DISCARD_QUESTION, {
        questionIds: questionIds,
      });
    } catch (e: any) {
      console.error('discardQuestion', e);
      throw new Error(`Impossibile cestinare il quesito. (Dettaglio dell'errore: ${JSON.stringify(e)})`);
    }
  }

  async updateQuestion(question: UpdateQuestionRequest): Promise<void> {
    try {
      await ApiGateway.post(this.UPDATE_QUESTION, question);
    } catch (e: any) {
      console.error('updateQuestion', e);
      throw new Error(`Impossibile salvare il quesito. (Dettaglio dell'errore: ${JSON.stringify(e)})`);
    }
  }

  async definitiveRefuseQuestion(questionId: string, definitiveRefuseReason: string): Promise<void> {
    try {
      await ApiGateway.post(this.DEFINITIVE_REFUSE_QUESTION, { questionId, rejectReason: definitiveRefuseReason });
    } catch (e: any) {
      console.error('definitiveRefuseQuestion', e);
      throw new Error(
        `Impossibile rifiutare definitivamente il quesito. (Dettaglio dell'errore: ${JSON.stringify(e)})`,
      );
    }
  }

  async proposeAnswer(questionId: string) {
    try {
      await ApiGateway.post(this.PROPOSE_ANSWER, {
        questionId: questionId,
      });
    } catch (e: any) {
      console.error('proposeAnswer', e);
      throw new Error(`Impossibile salvare la risposta. (Dettaglio dell'errore: ${JSON.stringify(e)})`);
    }
  }

  async acceptAnswer(questionIds: string[]) {
    try {
      await ApiGateway.post(this.ACCEPT_ANSWER, {
        questionIds: questionIds,
      });
    } catch (e: any) {
      console.error('acceptAnswer', e);
      throw new Error(`Impossibile accettare la risposta. (Dettaglio dell'errore: ${JSON.stringify(e)})`);
    }
  }

  async refuseAnswer(questionIds: string[]) {
    try {
      await ApiGateway.post(this.REFUSE_ANSWER, {
        questionIds: questionIds,
      });
    } catch (e: any) {
      console.error('refuseAnswer', e);
      throw new Error(`Impossibile rifiutare la risposta. (Dettaglio dell'errore: ${JSON.stringify(e)})`);
    }
  }

  async integrateAnswer(questionId: string, userId: string) {
    try {
      await ApiGateway.post(this.INTEGRATE_ANSWER, {
        questionId,
        userId,
      });
    } catch (e: any) {
      console.error('integrateAnswer', e);
      throw new Error(`Impossibile integrare la risposta. (Dettaglio dell'errore: ${JSON.stringify(e)})`);
    }
  }

  async readyQuestion(questionIds: string[]) {
    try {
      await ApiGateway.post(this.READY_QUESTION, {
        questionIds: questionIds,
      });
    } catch (e: any) {
      console.error('setQuestionReadyForPublication', e);
      throw new Error(
        `Impossibile impostare la risposta come pronta per la pubblicazione. (Dettaglio dell'errore: ${JSON.stringify(
          e,
        )})`,
      );
    }
  }

  async notifyQuestion(questionIds: string[]) {
    try {
      await ApiGateway.post(this.NOTIFY_QUESTION, {
        questionIds: questionIds,
      });
    } catch (e: any) {
      console.error('notifyQuestion', e);
      throw new Error(`Impossibile notificare la risposta. (Dettaglio dell'errore: ${e})`);
    }
  }

  async unlockNotifiedQuestion(questionIds: string[]) {
    try {
      await ApiGateway.post(this.UNLOCK_NOTIFIED_QUESTION, {
        questionIds: questionIds,
      });
    } catch (e: any) {
      console.error('unlockNotifiedQuestion', e);
      throw new Error(`Impossibile sbloccare la domanda. (Dettaglio dell'errore: ${e})`);
    }
  }

  async publishQuestion(
    questionIds: string[],
    outputChannelId: string,
    outputRubricId?: string,
    outputSubRubricId?: string,
    publicationDate?: Date,
  ) {
    try {
      const request = {
        questionIds: questionIds,
        outputChannel: outputChannelId,
        outputRubric: outputRubricId,
        outputSubRubric: outputSubRubricId,
        blockedUntil: publicationDate
          ? moment(publicationDate).add(1, 'days').format(Constants.BACKEND_DATETIME_FORMAT)
          : undefined,
      };
      await ApiGateway.post(this.PUBLISH_QUESTION, request);
    } catch (e: any) {
      console.error('publishQuestion', e);
      throw new Error(`Impossibile pubblicare la risposta. (Dettaglio dell'errore: ${e})`);
    }
  }

  async putFeedback(feedback: QuestionDuplicatesFeedback) {
    try {
      await ApiGateway.post(this.PUT_QUESTION_DUPLICATES_FEEDBACK, {
        suggestions: feedback.duplicates.map((item) => {
          return {
            is_real_duplicate: item.isRealDuplicate,
            question_id: item.questionId,
          };
        }),
        question_id: feedback.questionId,
      });
    } catch (e: any) {
      console.error('putFeedback', e);
      throw new Error(`Impossibile salvare i feedback. (Dettaglio dell'errore: ${JSON.stringify(e)})`);
    }
  }

  async putFeedbackV2(feedback: QuestionDuplicateFeedbackV2) {
    try {
      await ApiGateway.post(this.PUT_QUESTION_DUPLICATES_FEEDBACK_V2, {
        questionId: feedback.question_id,
        is_real_duplicate: feedback.is_real_duplicate,
        duplicate_question_id: feedback.duplicate_question_id,
      });
    } catch (e: any) {
      console.error('putFeedbackV2 error', e);
      throw new Error(`Impossibile salvare i feedback. (Dettaglio dell'errore: ${JSON.stringify(e)})`);
    }
  }

  async republishQuestion(questionId: string) {
    try {
      await ApiGateway.post(this.REPUBLISH_QUESTION, {
        questionId: questionId,
      });
    } catch (e: any) {
      console.error('republishQuestion', e);
      throw new Error(`Impossibile ripubblicare la risposta. (Dettaglio dell'errore: ${e})`);
    }
  }

  async askValidation(questionIds: string[]) {
    try {
      const request = {
        questionIds: questionIds,
      };
      await ApiGateway.post(this.QUESTION_ASK_VALIDATION, request);
    } catch (e: any) {
      console.error('Removing Question From Output Channel', e);
      throw new Error(`Impossibile richiedere la validazione per il quesito. (Dettaglio dell'errore: ${e})`);
    }
  }

  async validateQuestion(questionId: string) {
    try {
      const request = {
        questionId,
      };
      await ApiGateway.post(this.VALIDATE_QUESTION, request);
    } catch (e: any) {
      console.error('validateQuestion', e);
      throw new Error(`Impossibile validare la risposta. (Dettaglio dell'errore: ${e})`);
    }
  }

  async removeQuestionFromOutputChannel(questionId: string, outputChannelId: string) {
    try {
      const request = {
        questionId,
        outputChannel: outputChannelId,
      };
      await ApiGateway.post(this.REMOVE_QUESTION_FROM_OUTPUT_CHANNEL, request);
    } catch (e: any) {
      console.error('Removing Question From Output Channel', e);
      throw new Error(`Impossibile rimuovere il quesito dal canale. (Dettaglio dell'errore: ${e})`);
    }
  }

  async unlockPublishedQuestion(questionIds: string[]) {
    try {
      await ApiGateway.post(this.UNLOCK_PUBLISHED_QUESTION, {
        questionIds: questionIds,
      });
    } catch (e: any) {
      console.error('unlockPublishedQuestion', e);
      throw new Error(`Impossibile sbloccare la domanda. (Dettaglio dell'errore: ${e})`);
    }
  }

  async unpublishQuestion(questionId: string) {
    try {
      const request = { questionId };
      await ApiGateway.post(this.UNPUBLISH_QUESTION, request);
    } catch (e: any) {
      console.error('unpublishQuestion', e);
      throw new Error(`Impossibile rimuovere il quesito dalla pubblicazione. (Dettaglio dell'errore: ${e})`);
    }
  }

  async reClassify(questionId: string): Promise<Classification[]> {
    try {
      let response = await ApiGateway.post(this.RECLASSIFY_QUESTION, {
        questionId: questionId,
      });
      return response.data;
    } catch (e: any) {
      console.error('reclassifyQuestion', e);
      throw new Error('Impossibile re-categorizzare il quesito: ' + e.toString());
    }
  }

  async addComment(questionId: string, text: string): Promise<void> {
    try {
      await ApiGateway.post(this.ADD_COMMENT, {
        questionId,
        text,
      });
    } catch (e) {
      throw new Error('Impossibile aggiungere commento: ' + JSON.stringify(e));
    }
  }

  async deleteComment(commentId: string): Promise<void> {
    try {
      await ApiGateway.delete(`${this.DELETE_COMMENT}/${commentId}`);
    } catch (e) {
      throw new Error('Impossibile rimuovere commento: ' + JSON.stringify(e));
    }
  }

  async getDashboardStatusQuesiti(filters: {
    channel?: string[];
    status?: string[];
    typology?: string[];
    creationDate: [string, string];
  }) {
    try {
      console.log('getDashboardStatusQuesiti', filters);
      const response = await ApiGateway.post(this.REPORT_STATUS_QUESITI, filters);
      return response.data;
    } catch (e: any) {
      throw new Error('Impossibile recuperare gli stati dei quesiti: ' + e.toString());
    }
  }

  async getGenAIfromLambda(questionQ: string, questionId: string): Promise<GeneratedAnswer> {
    try {
      const response = await ApiGateway.postGenAiFromLambda(this.CREATE_GENAI_ANSWER, { 'question': questionQ, 'questionId': questionId }).then(resp => resp.data);
      return response;
    } catch (e: any) {
      throw new Error('Impossibile recuperare la risposta automatica generata da Gen-AI: ' + e.toString());
    }
  }

  async addFeedback(answerGenAiId: string, feedbackExpert: string, numericFeedback: number): Promise<void> {
    try {
      await ApiGateway.post(this.ADD_FEEDBACK_GENAI, {
        answerGenAiId,
        feedbackExpert,
        numericFeedback
      });
    } catch (e) {
      throw new Error('Impossibile aggiungere il feedback: ' + JSON.stringify(e));
    }
  }

  private mapResponseToQueryResponse(response: any): QueryResponse {
    const questions = response.data.questions.map(this.mapResponseToQuestion);
    return {
      fullCount: response.data.fullCount,
      questions: questions,
    };
  }

  private mapResponseToQuestion(question: any): Question {
    const questionClass = new Question(
      question.id,
      question.channelId,
      question.rubricId,
      question.subRubricId,
      question.subject,
      question.status,
      question.typology,
      question.creationDate,
    );

    questionClass.key = question.id;
    questionClass.channelName = question.channelName;
    questionClass.rubricName = question.rubricName;
    questionClass.subRubricName = question.subRubricName;
    questionClass.assignedTo = question.assignedTo;
    questionClass.signerUsers = question.signerUsers;
    questionClass.blockedUntil = question.blockedUntil;
    questionClass.publicationDetails = question.publicationDetails;
    questionClass.commentsCount = question.commentsCount;
    questionClass.isInContainer = question.isInContainer;
    questionClass.classificationsCount = question.classificationsCount;
    questionClass.containerInfo = question.containerInfo;
    questionClass.deadline = question.deadline;
    questionClass.versionNumber = question.versionNumber;

    return questionClass;
  }

  private mapResponseToQuestionDetail(question: any, duplicates: any, similars: any): QuestionDetail {
    const questionClass = new QuestionDetail(
      question.id,
      question.channelId,
      question.rubricId,
      question.subRubricId,
      question.subject,
      question.status,
      question.typology,
      question.creationDate,
      question.question,
    );

    questionClass.key = question.id;
    questionClass.channelName = question.channelName;
    questionClass.duplicates = duplicates.suggestions.map((duplicated: any) => {
      const questionDuplicateClass = new QuestionDuplicated(
        duplicated.questionId,
        duplicated.questionText,
        duplicated.answerText,
        duplicated.score,
      );

      questionDuplicateClass.isRealDuplicate = duplicated.isRealDuplicate;
      questionDuplicateClass.key = duplicated.questionId;
      questionDuplicateClass.rubric = duplicated.questionRubric;

      return questionDuplicateClass;
    });
    questionClass.similars = similars.suggestions.map((similar: any) => {
      const questionSimilarClass = new QuestionSimilar(
        similar.questionId,
        similar.questionText,
        similar.answerText,
      );
      questionSimilarClass.key = similar.questionId;
      questionSimilarClass.rubric = similar.questionRubric;

      return questionSimilarClass;
    });
    questionClass.rubricName = question.rubricName;
    questionClass.subRubricName = question.subRubricName;
    questionClass.assignedTo = question.assignedTo;
    questionClass.blockedUntil = question.blockedUntil;
    questionClass.publicationDetails = question.publicationDetails;
    questionClass.isInContainer = question.isInContainer;
    questionClass.editorSubject = question.editorSubject;
    questionClass.editorQuestion = question.editorQuestion;
    questionClass.classifications = question.classifications;
    questionClass.classificationsCount = question.classificationsCount;
    questionClass.endUserId = question.endUserId;
    questionClass.endUserName = question.endUserName;
    questionClass.endUserSurname = question.endUserSurname;
    questionClass.endUserCity = question.endUserCity;
    questionClass.endUserPhone = question.endUserPhone;
    questionClass.endUserEmail = question.endUserEmail;
    questionClass.infoRequestedDate = question.infoRequestedDate;
    questionClass.chats = question.chats;
    questionClass.definitiveRefuseReason = question.definitiveRefuseReason;
    questionClass.comments = question.comments;
    questionClass.answers = question.answers;
    questionClass.deadline = question.deadline;
    questionClass.answerGenAi = question.answerGenAi;
    questionClass.answerGenAiId = question.answerGenAiId;
    questionClass.feedbackAdded = question.feedbackAdded || false;

    return questionClass;
  }
}

import React, { useState } from 'react';

import { Button, Col, Collapse, Form, FormInstance, Input, Row, Tag, Divider } from 'antd';
import { DislikeOutlined, ExclamationCircleOutlined, LikeOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { QuestionDetail, QuestionDuplicated } from '../../../models/Question';
import './EditorFieldsFormItem.css';
import CurrentUser from '../../../models/CurrentUser';
import OrganizationService from '../../../services/OrganizationService';
import QuestionService from '../../../services/QuestionService';
import UserService from '../../../services/UserService';
import UserAssignableToQuestion from '../../../models/UserAssignableToQuestion';

import './QuestionFormItem.css';

const { Panel } = Collapse;
const { TextArea } = Input;

interface Props {
  currentUser: CurrentUser;
  question: QuestionDetail;
  questionForm: FormInstance<any>;
  organizationService: OrganizationService;
  userService: UserService;
  assignedTo: UserAssignableToQuestion | undefined;
  setAssignedTo: React.Dispatch<React.SetStateAction<UserAssignableToQuestion | undefined>>;
  canEdit: () => boolean;
  canEditRubricAndSubRubric: () => boolean;
  canEditClassifications: () => boolean;
}

const questionService = new QuestionService();

const QuestionFormItem: React.FC<Props> = (props) => {
  const duplicates = props.question.duplicates;
  const similars = props.question.similars;

  const [value, setValue] = useState([...duplicates]);

  const handleThumbs = (duplicated: QuestionDuplicated, index: number, isRealDuplicate: boolean, event: any) => {
    event.stopPropagation();

    // internal component state
    const newInternalValue = [...value];
    newInternalValue[index].isRealDuplicate = isRealDuplicate;
    setValue(newInternalValue);

    questionService.putFeedbackV2({
      question_id: props.question.id,
      is_real_duplicate: isRealDuplicate,
      duplicate_question_id: duplicated.id.toString(),
    });
  };
  // #endregion ----------------------------------------------------------------
  const canCategorizeDuplicate = props.currentUser.isAtLeastEditor() && props.question.status === 'ING';
  const hasDuplicates = duplicates.length > 0;
  const hasSummarization = similars.length > 0;
  const hasNoDuplicatesAndNoSummarization = !hasDuplicates && !hasSummarization;

  return (
    <>
      <Form.Item name="subject" data-testid="question-modal-form-subject">
        <Input readOnly={props.canEdit() === false} placeholder={'Oggetto'} />
      </Form.Item>

      <Form.Item name="question" data-testid="question-modal-form-question">
        <TextArea readOnly={props.canEdit() === false} rows={10} placeholder={'Domanda'} />
      </Form.Item>
      {canCategorizeDuplicate && !hasSummarization && !hasNoDuplicatesAndNoSummarization && (
        <>Questo quesito non ha simili in archivio</>
      )}
      {canCategorizeDuplicate && hasSummarization && (
        <>
          <Row align="middle" className="duplicates-header">
            <Col span={1}>
              <QuestionCircleOutlined
                className="summarization-headerIcon"
                style={{
                  fontSize: '24px',
                }}
              />
            </Col>
            <Col span={17}>
              <h2>Simili indentificati</h2>
              <p>Sono stati indentificati dei simili per questo quesito</p>
            </Col>
          </Row>

          <Collapse className="duplicatesItems" defaultActiveKey={[similars[0].id]} ghost>
            {similars.map((similar, index) => {
              if (!similar.id) {
                return null; // Non renderizzare nulla se l'id non c'è
              }
              return (
                <Panel
                  header={
                    <>
                      <Tag>{similar.id}</Tag> Rubrica: {similar.rubric}
                    </>
                  }
                  key={similar.id}
                >
                  <Form.Item label="Domanda">
                    <TextArea readOnly={true} rows={5} value={similar.question} />
                  </Form.Item>

                  <Form.Item label="Risposta">
                    <TextArea readOnly={true} rows={5} value={similar.answer} />
                  </Form.Item>
                </Panel>
              );
            })}
          </Collapse>
        </>
      )}
      {canCategorizeDuplicate && !hasNoDuplicatesAndNoSummarization && <Divider />}
      {canCategorizeDuplicate && !hasDuplicates && !hasNoDuplicatesAndNoSummarization && (
        <>Questo quesito non ha duplicati</>
      )}
      {canCategorizeDuplicate && hasDuplicates && (
        <>
          <Row align="middle" className="duplicates-header">
            <Col span={1}>
              <ExclamationCircleOutlined
                className="duplicates-headerIcon"
                style={{
                  fontSize: '24px',
                }}
              />
            </Col>
            <Col span={17}>
              <h2>Doppi indentificati</h2>
              <p>Sono stati indentificati dei doppi per questo quesito</p>
            </Col>
          </Row>

          <Collapse className="duplicatesItems" defaultActiveKey={[duplicates[0].id]} ghost>
            {duplicates.map((duplicated, index) => {
              return (
                <Panel
                  extra={
                    <>
                      <Button
                        icon={
                          <DislikeOutlined
                            onClick={(event) => {
                              handleThumbs(duplicated, index, false, event);
                            }}
                          />
                        }
                        type={duplicated.isRealDuplicate === false ? 'primary' : 'default'}
                      ></Button>
                      <Button
                        icon={
                          <LikeOutlined
                            onClick={(event) => {
                              handleThumbs(duplicated, index, true, event);
                            }}
                          />
                        }
                        type={duplicated.isRealDuplicate === true ? 'primary' : 'default'}
                      ></Button>
                    </>
                  }
                  header={
                    <>
                      <Tag>{duplicated.id}</Tag> Rubrica: {duplicated.rubric}
                    </>
                  }
                  key={duplicated.id}
                >
                  <Form.Item label="Domanda">
                    <TextArea readOnly={true} rows={5} value={duplicated.question} />
                  </Form.Item>

                  <Form.Item label="Risposta">
                    <TextArea readOnly={true} rows={5} value={duplicated.answer} />
                  </Form.Item>
                </Panel>
              );
            })}
          </Collapse>
        </>
      )}
      {canCategorizeDuplicate && hasNoDuplicatesAndNoSummarization && (
        <>Questo quesito non ha simili o doppi in archivio</>
      )}
    </>
  );
};

export default QuestionFormItem;

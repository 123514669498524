import moment from 'moment';
import QuestionStatus from '../utils/QuestionStatus';
import { DisplayUser } from './DisplayUser';

export interface Classification {
  classificationId: string;
  manual: boolean;
  classificationValue: string;
  rank: number;
}

export interface Chat {
  chatId: string;
  chatMessage: string;
  chatCreationDate: Date;
  sender: Sender;
}

export enum Sender {
  EndUser = 'EndUser',
  BackOffice = 'BackOffice',
}

export interface Comment {
  id: string;
  text: string;
  creationDate: Date;
  user: DisplayUser;
}

export interface Answer {
  id: string;
  answerDate: Date;
  answeredBy: DisplayUser[];
  answer: string;
  abstract: string;
  legalReferences: string[];
  creationDate: Date;
  editorAnswer: string;
}

export interface PublicationDetails {
  outputChannel: string;
  outputChannelName?: string;
  containerName?: string;
  publisherName?: string;
  publicationDate: Date;
}

export class Question {
  key?: string;
  channelName?: string;
  rubricName?: string;
  subRubricName?: string;
  assignedTo?: DisplayUser;
  signerUsers?: DisplayUser[];
  blockedUntil?: Date;
  publicationDetails?: PublicationDetails[];
  commentsCount?: number;
  classificationsCount?: number;
  isInContainer?: boolean;
  containerInfo?: {
    containerRubricId: string;
    containerRubricName: string;
    containerSubRubricId: string;
    containerSubRubricName: string;
  };
  deadline?: Date;
  versionNumber?: number;

  constructor(
    public id: string,
    public channelId: string,
    public rubricId: string,
    public subRubricId: string,
    public subject: string,
    public status: string,
    public typology: string,
    public creationDate: Date,
  ) {}

  isBlockedForPublication(): boolean {
    if (this.blockedUntil && moment(this.blockedUntil).startOf('day').isAfter(moment().startOf('day'))) {
      return true;
    }
    return false;
  }

  hasActiveDeadline(): boolean {
    let hasDeadline = this.deadline !== undefined && this.deadline !== null;
    return hasDeadline && QuestionStatus.beforeNOTIFIED(this.status);
  }
}

export class QuestionDuplicated {
  isRealDuplicate?: boolean;
  key?: string;
  rubric?: string;

  constructor(public id: string, public question: string, public answer: string, public score: number) {}
}

export class QuestionSimilar {
  key?: string;
  rubric?: string;

  constructor(public id: string, public question: string, public answer: string) { }
}

export class QuestionDuplicatesFeedback {
  constructor(
    public questionId: string,
    public duplicates: [
      {
        isRealDuplicate?: boolean;
        questionId: string;
      },
    ],
  ) {}
}

export interface QuestionDuplicateFeedbackV2 {
  question_id: string;
  is_real_duplicate: boolean;
  duplicate_question_id: string;
}

export class QuestionDetail extends Question {
  editorSubject?: string;
  editorQuestion?: string;
  classifications?: Classification[];
  duplicates: QuestionDuplicated[] = [];
  endUserId?: string;
  endUserName?: string;
  endUserSurname?: string;
  endUserCity?: string;
  endUserPhone?: string;
  endUserEmail?: string;
  infoRequestedDate?: Date;
  chats?: Chat[];
  definitiveRefuseReason?: string;
  comments?: Comment[];
  answers?: Answer[];
  answerGenAi?: string;
  answerGenAiId?: string;
  feedbackAdded?: boolean;
  similars: QuestionSimilar[] = [];

  constructor(
    public id: string,
    public channelId: string,
    public rubricId: string,
    public subRubricId: string,
    public subject: string,
    public status: string,
    public typology: string,
    public creationDate: Date,
    public question: string,
  ) {
    super(id, channelId, rubricId, subRubricId, subject, status, typology, creationDate);
  }
}

export interface QueryResponse {
  readonly questions: Question[];
  readonly fullCount: number;
}
